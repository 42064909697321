import React, { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SetSearchResults } from "../../store/actions/menuAction";
import { useNavigate } from "react-router-dom";
import SearcgBarModal from "../modals/SearcgBarModal";
import ModalInterface from "../../common/ModalInterface";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { updateSessionTable } from "../../store/actions/tableActions";

const SearchBar = ({ searchSectionRef }) => {
  // const table = useSelector((state) => state.menu.table);
  const business_name = useSelector((state) => state.table.business_Info.business_name);
  const themeSettings = useSelector((state) => state?.table?.business_Info?.page_layout?.instore);

  const filters = new Set();
  const [showSearch, setShowSearch] = useState(false);
  const [query, setQuery] = useState("");
  const [SearchFound, SetSearchFound] = useState(false);
  const [showcatogorymsg, setShowcatogorymsg] = useState(false);
  const [tableNameModal, setTableNameModal] = useState(false);

  let navigate = useNavigate();

  const dispatch = useDispatch();
  const table = useSelector((state) => state.table);
  const menuData = useSelector((state) => state.menu.menu);
  const vegans = useSelector((state) => state.menu.veganItems);
  const specials = useSelector((state) => state.special.special);
  const loading = useSelector((state) => state.menu.loading);
  const floorPlans = useSelector((state) => state.table.floorPlans);

  const data = [menuData, vegans, specials].flat();
  const [floorPlanData, setFloorPlanData] = useState([]);
  const [selectedTable, setSelectedTable] = useState("");
  const [errorMoadal, setErrorMoadal] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const updateTableLoader = useSelector((state) => state.table.updateTableLoader);
  const page_theme = useSelector((state) => state.table?.business_Info?.page_layout?.instore?.cart);
  const sessionId = useSelector((state) => state.table.sessionId);

  useEffect(() => {
    if (floorPlans) {
      let floorPlanArray = [{ value: "", label: "Select" }];
      floorPlans.forEach((_item) => {
        if (![1, 2, 3, 4].includes(_item?.number)) {
          floorPlanArray.push({ value: _item?.number, label: _item?.number });
        }
      });
      setFloorPlanData([...floorPlanArray]);
    }
  }, [floorPlans]);

  // const subHeaderTheme = useSelector(
  //   (state) => state.table?.business_Info?.page_layout?.instore?.menu
  // );

  let validateQuery = [];
  const category = [];

  data?.forEach((item) => {
    item?.categories?.map((it) => {
      category.push(it);
    });
  });
  const handalCategorymsg = () => {
    setShowcatogorymsg(true);
  };
  const checkFilters = (item) => {
    if (filters.has(item)) {
      filters.delete(item);
    } else {
      filters.add(item);
    }
  };

  const updateResults = (queryResult) => {
    dispatch(SetSearchResults(queryResult));

    if (window.location.pathname !== "/home") {
      navigate("/home");
      setShowSearch(false);
    }
    window.scrollTo(0, searchSectionRef.current.offsetTop - 100);
    setQuery("");
    setShowSearch(false);
  };

  const searchItems = () => {
    if (window.location.pathname !== "/home") {
      navigate("/home");
      setShowSearch(false);
      setQuery("");
    }

    let queryResult = [];

    //find query in names of each item
    data.filter((item) => {
      if (item?.name?.toLowerCase().includes(query.trim().toLowerCase())) {
        return validateQuery.push(item);
      }
    });
    //find query in description of each item
    data.filter((item) => {
      if (item?.description?.toLowerCase().includes(query.trim().toLowerCase())) {
        return validateQuery.push(item);
      }
    });

    if (!validateQuery?.length) {
      SetSearchFound(true);
      setQuery("");
    }
    let reult = [];
    validateQuery.forEach((item, index) => {
      if (!index) {
        console.log(item);
        console.log(Array.from(filters));
      }
      Array.from(filters).forEach((itm) => {
        let inde = item.categories.findIndex((x) => x._id === itm._id);
        if (inde !== -1) {
          reult.push(item);
        }
      });
    });
    if (validateQuery.length > 0) {
      if (Array.from(filters).length > 0) {
        queryResult.push(reult);
      } else {
        queryResult.push(validateQuery);
      }
      updateResults(queryResult);
    }
  };
  const findWithFilters = () => {
    if (filters?.size > 0 && query?.length <= 0) {
      let exectlyMatch = [];
      let obj = {};
      let ismatch = true;
      let noexectlyMatch = [];
      Array.from(filters).forEach((itm) => {
        obj[itm._id] = itm._id;
      });
      data.forEach((cat_item) => {
        ismatch = true;
        if (cat_item.categories.length === Array.from(filters).length) {
          cat_item.categories.forEach((itms) => {
            if (obj[itms._id] === undefined) {
              ismatch = false;
            }
          });
          if (ismatch) {
            exectlyMatch.push(cat_item);
          }
        }
        if (cat_item.categories.length > Array.from(filters).length) {
          ismatch = true;
          Array.from(filters).forEach((filter_item) => {
            if (!cat_item.categories.some((e) => e._id === filter_item._id)) {
              ismatch = false;
            }
          });
          if (ismatch) {
            noexectlyMatch.push(cat_item);
          }
        }
      });
      updateResults([...exectlyMatch, ...noexectlyMatch]);
    } else if (filters.size <= 0 && query.length > 0) {
      searchItems();
    } else if (filters.size > 0 && query.length > 0) {
      searchItems();
    } else if (filters.size <= 0 && query.length <= 0) {
      handalCategorymsg();

      // alert("Please select a category or enter text to filter");
    }
  };

  useEffect(() => {
    setQuery("");
    setShowSearch(false);
    dispatch(SetSearchResults([]));
  }, []);
  const updateSessionHandler = () => {
    if (!selectedTable) {
      setErrorMoadal(true);
      setErrorMsg("Bitte wähle einen Tisch.");
      return;
    }
    dispatch(updateSessionTable({ id: sessionId, table_id: selectedTable })).then((res) => {
      setTableNameModal(false);
      if (!res?.success) {
        setErrorMoadal(true);
        setErrorMsg(res?.response?.data?.message);
        return;
      }
    });
  };
  console.log(table?.orderInfo);
  return (
    <>
      <Navbar
        className="d-flex justify-content-between p-0 align-items-center firstNav ps-2 m-0"
        style={{ height: "53px" }}>
        <Nav.Item
          onClick={() => {
            setTableNameModal(true);
          }}
          style={{
            color: "#000",
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "0px",
            cursor: "pointer"
          }}>
          <h6 className="mb-0">
            TISCH:{" "}
            {table?.table != null || ""
              ? `${
                  table?.orderInfo?.tableNumber == 1 ? "___" : table?.orderInfo?.tableNumber
                } P : ${table?.orderInfo?.pagerNumber}`
              : "please join or create a TISCH"}
          </h6>{" "}
        </Nav.Item>

        <Nav.Item>
          <div className="searchlocation ps-md-2 ms-lg-5 pe-0 me-3">
            <span style={{ marginRight: "10px" }}> {business_name}</span>{" "}
            <div
              role={"button"}
              className=" ms-3 px-2"
              style={
                loading
                  ? {
                      borderRadius: "20px",
                      backgroundColor: "#eb8e9e"
                    }
                  : {
                      borderRadius: "20px",
                      backgroundColor: themeSettings?.menu?.primary_search_btn_bgcolor
                    }
              }>
              <span
                style={{ width: "40px", height: "40px" }}
                onClick={() => {
                  if (!loading) {
                    if (window.location.pathname !== "/home") {
                      navigate("/home");
                      setShowSearch(true);
                    } else {
                      setShowSearch(true);
                    }
                  }
                }}>
                <img
                  src="./img/sortWhite.png"
                  alt="sort"
                  style={{ width: "20px", height: "20px" }}></img>
              </span>
              <i
                className="fa fa-search text-white ps-2"
                onClick={() => {
                  if (!loading) {
                    setShowSearch(true);
                  }
                }}></i>
            </div>
          </div>
        </Nav.Item>
      </Navbar>

      <SearcgBarModal
        showSearch={showSearch}
        setShowSearch={setShowSearch}
        searchItem={searchItems}
        findWithFilters={findWithFilters}
        query={query}
        setQuery={setQuery}
        checkFilters={checkFilters}
        categories={category}
        filters={filters}
        SearchFound={SearchFound}
        SetSearchFound={SetSearchFound}
        themeSettings={themeSettings}
      />
      <ModalInterface
        display={showcatogorymsg}
        size="sm"
        center="true"
        handleClose={async () => {
          setShowcatogorymsg(false);
        }}
        title={
          <>
            <div className="d-flex justify-content-end p-2">
              <span
                className="bg-danger rounded-circle p-2 d-flex align-items-center justify-content-center text-white"
                style={{ height: "25px", width: "25px" }}>
                <i
                  className="fa fa-close fs-4"
                  onClick={async () => {
                    setShowcatogorymsg(false);
                  }}></i>
              </span>
            </div>
          </>
        }
        content={
          <>
            <h4>Please select a category or enter text to filter</h4>
          </>
        }
      />
      <ModalInterface
        display={tableNameModal}
        center="true"
        handleClose={() => {
          setTableNameModal(false);
        }}
        title={<div className="mt-3 text-center">TABLES</div>}
        closeEvent={() => setTableNameModal(false)}
        size="lg"
        content={
          <>
            <Form>
              <Form.Group className="mb-3">
                <Select
                  isClearable={false}
                  isSearchable={true}
                  className="basic-single"
                  classNamePrefix="select"
                  name="floorPlans"
                  options={floorPlanData}
                  onChange={(e) => {
                    setSelectedTable(e.value);
                  }}
                />
              </Form.Group>
            </Form>
            <div className="d-flex justify-content-end">
              <Button
                disabled={updateTableLoader}
                style={{
                  borderRadius: "30px",
                  border: "none",
                  width: "150px",
                  color: page_theme?.primary_place_order_btn_text_color,
                  backgroundColor: page_theme?.primary_place_order_btn_bgcolor
                  // fontSize: "16px",
                }}
                className={`mx-3 font-${page_theme?.font_family_primary_place_order_btn} fs-${page_theme?.font_size_primary_place_order_btn}`}
                onClick={updateSessionHandler}>
                {updateTableLoader ? (
                  <span
                    className="spinner-border spinner-border-lg text-danger text-center"
                    role="status"
                    aria-hidden="true"></span>
                ) : (
                  <span> Assign Table</span>
                )}
              </Button>
              <Button
                onClick={() => setTableNameModal(false)}
                style={{
                  borderRadius: "30px",
                  border: "none",
                  width: "150px",
                  color: page_theme?.primary_place_order_btn_bgcolor,
                  backgroundColor: page_theme?.primary_place_order_btn_text_color
                  // fontSize: "16px",
                }}>
                Close
              </Button>
            </div>
          </>
        }
      />
      <ModalInterface
        display={errorMoadal}
        center="true"
        handleClose={() => {
          setErrorMoadal(false);
        }}
        title={<></>}
        closeEvent={() => setErrorMoadal(false)}
        content={
          <>
            <div className="text-center divideCalc3 mb-2">
              <div className="text-center divideCalc3 mb-2">
                <h1> {errorMsg}</h1>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default SearchBar;
