/* eslint-disable no-lone-blocks */
/* eslint-disable array-callback-return */
// import { useState } from "react";
import {
  Row,
  Col,
  //  Form,
  Stack
} from "react-bootstrap";
import { useSelector } from "react-redux";
import "../../assets/css/orderBill.css";

export const OrderBill = (props) => {
  const page_theme = useSelector((state) => state.table?.business_Info?.page_layout?.instore?.bill);

  // const { TotalCart, ListCart, TotalPrice } = props;
  const { ListCart } = props;
  const courses = useSelector((state) => state.order.courses);
  const { ordersPlaced } = useSelector((state) => state.order);
  // const placedOrder = useSelector((state) => state.order.ordersPlaced); //after placing order getting disabled value from saleEntries array
  let se = ordersPlaced?.flat()?.map((btx) => btx?.salesEntries);
  const orders = se
    ?.flat()
    ?.sort((a, b) => (a?.course > b?.course ? 1 : b?.course > a?.course ? -1 : 0));
  // adding each line item seperately
  const splitArray = [];
  const caourseObj = {
    starters: "Vorspeise",
    starter: "Vorspeise",
    "main course": "Hauptgericht",
    dessert: "Nachtisch",
    "to drink": "Getränke"
  };
  orders?.forEach((item) => {
    if (item.payment_status !== "paid") {
      if (item?.quantity > 1) {
        for (let i = 0; i < item?.quantity; i++) {
          splitArray.push(item);
        }
      } else {
        splitArray.push(item);
      }
    }
  });

  // let selectedEntries = [];

  // const [checkedState, setCheckedState] = useState([]); //checked state array represents index of splitArr
  const bill = Array(splitArray?.length).fill(0); //indexing of bill is equal to indexing of split array

  const addToBill = () => {
    let finalBill = bill?.reduce(function (a, b) {
      return a + b;
    }, 0);
    props?.setFinalBill(finalBill);
    return finalBill.toFixed(2);
  };

  // const handleOnChange = (event) => {
  //   if (event.target.checked === true) {
  //     props?.setCountToBill(props?.countToBill + 1);
  //   } else {
  //     props?.setCountToBill(props?.countToBill - 1);
  //   }
  //   let updatedCheckedState = [...checkedState, event.target.id];
  //   if (checkedState.includes(event.target.id)) {
  //     updatedCheckedState = updatedCheckedState.filter((item) => item !== event.target.id);
  //   }
  //   setCheckedState(updatedCheckedState);

  //   let totalPrice = updatedCheckedState.reduce((sum, currentState) => {
  //     return sum + bill[parseInt(currentState) - 1]; //-1 because we used key+1 as id
  //   }, 0);
  //   // totalPrice = totalPrice.toFixed(2);
  //   props.setSelectedBill(totalPrice);

  //   updatedCheckedState.forEach((selectedEntry) => {
  //     let itemToFind = splitArray[selectedEntry - 1];

  //     let indexOfSE = selectedEntries.findIndex((entry) => entry._id === itemToFind._id);

  //     if (indexOfSE >= 0) {
  //       selectedEntries[indexOfSE].quantity++;
  //     } else {
  //       selectedEntries.push(JSON.parse(JSON.stringify(splitArray[selectedEntry - 1])));
  //       selectedEntries[selectedEntries.length - 1].quantity = 1;
  //     }
  //   });
  //   props.setEntries(selectedEntries);
  // };
  return (
    <div
      style={{
        maxHeight: ListCart.length > 2 ? "400px" : "auto",
        overflowY: ListCart.length > 2 ? "scroll" : "hidden",
        overflowX: "clip"

        // '&::-webkit-scrollbar': { overflowY: 'scroll', width: ' 0', }
      }}
      className="removeScroll">
      {courses?.map((singleCourse, key1) => {
        var itemCount = 0;
        return (
          <>
            {splitArray?.map((item, key) => {
              {
                // return new Array(item?.quantity).fill(0).map((_, itemIndex) => {
                const globalIndex = key + 1; //fancy name for index of splitArr

                if (item?.course === singleCourse?.title) {
                  itemCount += 1; //show course heading only once
                  let ingredientPrices = [];
                  let restt = 0;
                  let price = 0;
                  let totalItemPrice = 0;

                  const itemPrice = () => {
                    price = item?.itemPrice;
                    item?.subLineItems
                      ?.filter((ingredient_) => {
                        return ingredient_?.selected === true;
                      })
                      ?.forEach((ing) => {
                        return ingredientPrices.push(Number(ing?.itemPrice));
                      });

                    restt = ingredientPrices?.reduce(function (a, b) {
                      return a + b;
                    }, 0);

                    if (typeof restt !== "number" || isNaN(restt)) {
                      totalItemPrice = Number(price);
                    } else {
                      totalItemPrice = Number(restt) + Number(price);
                    }
                    bill[key] = totalItemPrice;

                    return totalItemPrice.toFixed(2);
                  };

                  return (
                    <>
                      {/* course title shown once only */}
                      {itemCount === 1 && (
                        <h4
                          className="text-uppercase"
                          key={key1}
                          style={{
                            fontSize: "24px",
                            fontWeight: "500",
                            lineHeight: "1.2"
                          }}>
                          {caourseObj[singleCourse?.title]}
                        </h4>
                      )}
                      {/* <div key={item.id}> */}
                      <div key={100 + globalIndex}>
                        <Stack
                          direction="horizontal"
                          className="align-items-baseline d-flex justify-content-between">
                          <Col>
                            <span
                              style={{
                                fontSize: "15px",
                                fontWeight: 600
                              }}>
                              {item?.itemName}
                              <p
                                className="pt-4"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400"
                                }}>
                                {item?.subLineItems?.map((ing, ingIndex) => {
                                  if (ing?.type === "normal" || ing?.type === "Extras") {
                                    return (
                                      <>
                                        <span key={200 + ingIndex}>{ing?.itemName}</span>{" "}
                                        <b>{" - "}</b>
                                      </>
                                    );
                                  }
                                  return;
                                })}
                              </p>
                            </span>
                          </Col>
                          <Col
                            lg={2}
                            sm={1}
                            md={2}
                            style={{
                              fontSize: "17px",
                              // paddingBottom: "10px",
                              fontWeight: 600
                            }}>
                            <span
                              className="d-inline text-dark subAmount"
                              style={{
                                fontSize: "17px",
                                // paddingBottom: "10px",
                                fontWeight: 600
                              }}>
                              {itemPrice()}
                              {/* {props.TotalPrice(item.itemPrice, item.quantity)} € */}
                            </span>
                          </Col>
                          {/* {ordersPlaced[0]?.payment_status !== "pending" ||
                          props.paymentType === "online_payment" ? (
                            <Col lg={1} sm={1} md={2} style={{ textAlign: "end" }}>
                              {/* <Form.Check
                                disabled={item?.disabled}
                                size="lg"
                                type={"checkbox"}
                                className="hidden-checkbox"
                                id={globalIndex}
                                value={globalIndex}
                                onChange={(e) => {
                                  handleOnChange(e, globalIndex);
                                }}
                              /> 
                            </Col>
                          ) : null} */}
                        </Stack>
                      </div>
                    </>
                  );
                }
                // });
              }
            })}
          </>
        );
      })}
      <hr
        className=""
        style={{
          backgroundColor: page_theme?.primary_bill_line_color,
          opacity: 1,
          height: "2px",
          marginTop: "0px"
        }}
      />
      <div
        style={
          {
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "flex-end",
          }
        }>
        <Row>
          <Col style={{ fontSize: "30px" }}>{/* <h1> RECHNUNGSSUMME*</h1> */}</Col>
          <Col>
            <h2 className="text-dark" style={{ textAlign: "right", fontSize: "30px" }}>
              <span className="text-dark">
                {ordersPlaced[0]?.paid_amount > 0
                  ? (ordersPlaced[0]?.full_amount - ordersPlaced[0]?.paid_amount).toFixed(2)
                  : addToBill()}
                €{/* {Number(TotalCart).toLocaleString("en-US")}€ */}
              </span>
            </h2>
          </Col>
        </Row>
      </div>
    </div>
  );
};
